import React from 'react';
import './slide-up.scss';

const SlideUp = ({name, children}) => {
  return (
    <div className={'slide-up'}>

      <div className="slide-up__handle">
        <label className="slide-up__handle-label"
               htmlFor="slide-up__state">{name}</label>
      </div>

      <input type="checkbox" id="slide-up__state" className="slide-up__state" />

      <div className={'slide-up__contents'}>{children}</div>

    </div>
  );
};

export default SlideUp;
