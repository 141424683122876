import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import BackgroundImage from "gatsby-background-image"
// const ASPECT_RATIO = 5473 / 3654;

// Sm (1024 * 0.6) * (5473/3654) = 920.255
// DT (1280 * 0.6) * (5473/3654) = 1,150.319
// HD (1920 x 1080) (1920 * 0.6) * (5473/3654) = 1,725.478
// 2k (2560 x 1440) (2560 * 0.6) * (5473/3654) = 2,300.637
// 4k (3840 x 2160) (3840 * 0.6) * (5473/3654) = 3,450.956

// Save these here for experimenting
// const primary = '#0ec4f1';
// const shadow = "#192550";
// const colourBlue = 'rgb(38,100,255)'; //#2664FF
// const colourBlueBg = 'rgba(16, 23, 61, 1)'; //#10173D'
// const colourBlueBgBurn = 'rgba(16, 23, 61, 0.7)'; //#10173D

const HomepageBackgroundImage = ({ tag = 'div', className }) => {

  const {
    imageSmall,
    imageDesktop,
    imageHd
  } = useStaticQuery(
    graphql`
      query {
        # @1024: 60% of 1024
        imageSmall: file( relativePath: {eq: "hp-bg.jpg"}) {
          childImageSharp {
            fluid(
              quality: 70,
              maxWidth: 920,
              duotone: {
                highlight: "#0ec4f1",
                shadow: "#10173D",
                opacity: 70
              }
            ) { ...GatsbyImageSharpFluid_withWebp }
          }
        },
        imageDesktop: file( relativePath: {eq: "hp-bg.jpg"}) {
          childImageSharp {
            fluid(
              quality: 70,
              maxWidth: 1150,
              duotone: {
                highlight: "#0ec4f1",
                shadow: "#10173D",
                opacity: 70
              }
            )
            { ...GatsbyImageSharpFluid_withWebp }
          }
        },
        imageHd: file( relativePath: {eq: "hp-bg.jpg"}) {
          childImageSharp {
            fluid(
              quality: 70,
              maxWidth: 1725,
              duotone: {
                highlight: "#0ec4f1",
                shadow: "#10173D",
                opacity: 70
              }
            ) { ...GatsbyImageSharpFluid_withWebp }
          }
        }
      }
    `
  );

  const sources = [
    imageSmall.childImageSharp.fluid,
    {
      ...imageDesktop.childImageSharp.fluid,
      media: `(min-width: 1024px)`,
    },
    {
      ...imageHd.childImageSharp.fluid,
      media: `(min-width: 1280px)`,
    },
  ];

  return (
    <BackgroundImage
      Tag={`aside`}
      id={`aside`}
      className={className}
      fluid={sources}
    >
    </BackgroundImage>
  )
}

const HomepageBackground = styled(HomepageBackgroundImage)`
  width: 100%;
  background-position: bottom center;
  background-repeat: repeat-y;
  background-size: cover;
`;
// To target image:
// &::before,
// &::after {
//   // filter: saturate(90%);
// }


export default HomepageBackground;
